import { useNavigation } from '@remix-run/react';

// ensures we don't show the loading state when loading a page (vs. submitting a form)
// does not work if there are multiple forms on a page
//   (just implement via `useNavigation` yourself in that case, using e.g. navigation.formAction or navigation.formData)
export function useFormState() {
  const navigation = useNavigation();

  return {
    submitting:
      navigation.state === 'submitting' ||
      (navigation.state === 'loading' && !!navigation.formAction)
  };
}
